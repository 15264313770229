import styles from './QuemSomos.module.css'
import logo from '../../img/img_home/praAna.jpg'



function Quemsomos() {
    return (/*
        <>
        <section  >
            <div>
                <h1>
                    Quem Somos
                </h1>
            </div>
        </section>

            <section>
                <div className={styles.aboutUsContainer}>
                    <div>
                        <p>
                            A Igreja Batista da Lagoinha nasceu em Belo Horizonte em 20 de dezembro de 1957 e há 60 anos atua de forma relevante na expansão do evangelho.
                        </p>
                            A Sexta Igreja Batista, conforme seria chamada na época, iniciou com um grupo de 32 pessoas lideradas pelo Pastor José Rêgo do Nascimento.
                        <p>
                            Em 1972, atingiu um total de 300 membros e passou a ser liderada pelo Pastor Márcio Roberto Vieira Valadão, ainda hoje à frente da congregação.
                        </p>
                        <p>
                            Atualmente, por meio de um processo de expansão dinâmico, a Lagoinha conta com mais de 600 igrejas implantadas e cerca de 120 mil membros em diferentes lugares no Brasil e do mundo.
                        </p>
                        <p>
                            Em paralelo ao trabalho de expansão, a Lagoinha promove e contribui com o desenvolvimento de pessoas por meio de projetos destinados à ressocialização, atendimento jurídico gratuito, capacitação profissional, assistência espiritual, psicológica e familiar, entre outros, viabilizados tanto pelas Lagoinhas regionais quanto pelos departamentos internos instalados na sede.
                        </p>
                        <p>
                            O que começou em Belo Horizonte continua em Barueri. A Lagoinha Alphaville quer levar a palavra de Deus para todos, e desta forma, cumprir seu propósito criando novas oportunidades e mudando histórias por meio de Cristo e para Cristo.
                        </p>
                    </div>

                    
                    <img className={styles.img_de} src={logo} alt='imagem de noticia' title="imagem de noticia"/>
                </div>
            </section>

        </>
        */
        <>
        <main className={styles.quem_somos}>
       <div className={styles.heading}>
            <h1>Quem Somos</h1>
       </div>
       <div className={styles.container}>
            <section className={styles.about}>
                <div className={styles.about_image}>
                    <img src={logo} alt='imagem de noticia' title="imagem de noticia"/>
                </div>
                <div className={styles.about_content}>
                        <p>
                            Obediência à grande comissão é a única atitude que Deus requer para nos usar.                         </p>
                        <p>
                            Lagoinha Braga foi uma conexão de obediências;                        </p>
                        <p>
                            De um lado, uma família seguiu o sonho que Deus os havia dado e pediu a abertura da Lagoinha na cidade;                        </p>
                        <p>
                            De outro lado, uma missionária partiu para cumprir o chamado e, com a ajuda de Deus, tornar realidade esta implantação                        </p>
                        <p>
                            Nestes 7 Anos temos participado do mover de Deus em Portugal.                        </p>
                        <p>
                            E assim... a Lagoinha Braga multiplicou e, pela graça de Deus chegamos em outros lugares como em Guimarães, Barcelos, Famalicão, Póvoa de Lanhoso, Vila Verde e Nuremberg, na Alemanha.
                        </p>
                        <p>
                            Não vamos parar po aí, iremos aonde Deus quiser nos levar, em todo Portugal, de norte a sul,de leste a oeste. 
                            " o povo que andava nas trevas viu uma grande luz; sobre os que habitavam na terra da sombrada morte resplandeceu a luz" (Is 9:2) 
                            A glória de Deus replandecerá em Portugal para as Nações!
                        </p>
                        <p>
                            PRA. ANA PAULA MANZO
                        </p>
                </div>
            </section>
       </div>
       </main>
       </>
    )
}

export default Quemsomos
