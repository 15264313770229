import { getSmallGroups } from '../../services/smallGroupService'

function Gcs(){
    const hasComment = (comment) => {
        return comment === '';
    }

    const hasComplement = (complement) =>
    {
        return complement === '';
    }

    const listItems = getSmallGroups().map(smallGroup =>
        <div class="box has-background-text-55 has-text-black" key={smallGroup.id}>
            <h2 class="subtitle has-text-weight-bold  has-text-black has-text-centered has-background-warning">
                {smallGroup.title}
            </h2>
            <p>
                {smallGroup.dayOfWeek} às <time dateTime={smallGroup.startAt}>{smallGroup.startAt}h</time> <br />
                <address>{smallGroup.address.street} {smallGroup.address.number}<span hidden={hasComplement(smallGroup.address.complement)}>,</span> {smallGroup.address.complement} <br /> 
                {smallGroup.address.neighborhood} - {smallGroup.address.city}
                <a href={smallGroup.mapUrl} title="Clique para ver no mapa">
                    <span class="icon has-text-info">
                        <img width="18" height="18" src="https://img.icons8.com/dusk/64/map.png" alt="map"/>
                    </span>
                </a>
                </address>
            </p>
            <div class="notification is-link is-light" hidden={hasComment(smallGroup.comments)}>
                {smallGroup.comments}
            </div>
            <footer class="has-text-centered">
                <a href={ 'tel:' + smallGroup.contact } class="button is-warning is-light is-outlined" title="Clique para entrar em contacto com o grupo">
                    Mais informações
                </a>
            </footer>
        </div>
    );

    return (
        <>
        <main class="container is-fluid has-background-black">
        <header class="hero is-small has-text-centered has-text-white">
            <div class="hero-body">
                <h1 class="title is-uppercase">
                 GC
                </h1>
                <h2 class="subtitle">
                    Células <br />
                </h2>
                <p>
                    Veja qual grupo de crescimento está mais perto de você.
                </p>
            </div>
        </header>
        <section class="section">
            <div class="grid is-col-min-10">
                {listItems}
            </div>
        </section>
        </main>
        </>
    )
}

export default Gcs