import ibanIcon from '../../img/gives/iban-icon.png'
import mbWayIcon from '../../img/gives/mbway-icon.png'
import pixIcon from '../../img/gives/pix_icon.png'

function DizimosOfertas(){
    return (
        <>
        <main class="container is-fluid has-background-black">
        <header class="hero is-black is-small">
            <div class="hero-body">
                <p class="title has-text-centered is-uppercase">
                Dízimos e Ofertas
                </p>
            </div>
        </header>
        <section class="section has-text-centered">
            <header class=" has-text-white m-4">
                Faça sua contribuição através de uma das nossas contas bancárias.
            </header>
        </section>
        <section class="level">
            <div class="level-item">
                <figure class="image is-96x96" title="IBAN">
                    <img src={ibanIcon} alt="IBAN"/>
                </figure>
                <p class="has-text-white m-2">
                    <abbr title="International Bank Account Number ou Número de Conta Bancária Internacional em português">IBAN</abbr>: 
                    <address>PT50 0010 0000 <br />6119 3300 0013 9</address>
                </p>
            </div>
        </section>
        <section class="level">
            <div class="level-item">
                <figure class="image is-96x96" title="IBAN">
                    <img src={mbWayIcon} alt="MBWay" />
                </figure>
                <p class="has-text-white m-2">
                    <abbr title="O MB WAY é a solução MULTIBANCO que lhe permite fazer compras online e em lojas físicas, gerar cartões virtuais MB NET, enviar, pedir dinheiro e dividir a conta e ainda utilizar e levantar dinheiro através do seu smartphone, numa app própria ou nos canais do seu banco.">
                    MB Way:</abbr> <address>920 127 041</address>
                </p>
            </div>
        </section>
        <section class="level">
            <div class="level-item">
                <figure class="image is-96x96" title="Pix">
                    <img src={pixIcon} alt="Pix" />
                </figure>
                <p class="has-text-white m-2">
                    <abbr title="Pagamento Instantâneo Brasileiro">PIX</abbr>: <address>lagoinhabraga@gmail.com</address>
                </p>
            </div>
        </section>
        <footer class="section has-text-centered has-text-white">
            <p>
                "Cada um dê conforme determinou em seu coração, não com <br /> 
                pesar ou por obrigação, pois Deus ama quem dá com alegria." <br />
                2 Coríntios 9:7
            </p>
        </footer>
        </main>
        </>
    )
}

export default DizimosOfertas