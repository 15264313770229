import {Link} from 'react-router-dom'

import Container from "./Container"

import styles from "./Navbar.module.css"
import logo from '../../img/favicon.ico'

function Navbar(){
    return (
        
        <nav class={styles.navbar}>
            <Container>
                <Link to="/" title="Ir para a página inicial">
                    <img src={logo} alt="Logo IBLB" />
                </Link>
                <ul class={styles.list}>
                    <li class={styles.item}>
                        <Link to="/" title="Ir para a página inicial">
                            Home
                        </Link>
                    </li>
                    <li class={styles.item}>
                        <Link to="/quemsomos" title="Ir para a página quem somos">
                            Quem Somos
                        </Link>
                    </li>
                    <li class={styles.item}>
                        <Link to="/cultos" titlte="Ir para a página dos cultos">
                            Cultos
                        </Link>
                    </li>
                    <li class={styles.item}>
                        <Link to="/gcs">
                            GC'S
                        </Link>
                    </li>
                    <li class={styles.item}>
                        <Link to="/dizimosofertas" title="Ir para a página de dízimos e ofertas">
                            Dízimos e Ofertas
                        </Link>
                    </li>
                    <li class={styles.item}>
                        <Link to="/cursos" title="Ir para a págnia de cursos e formações">
                            Cursos
                        </Link>
                    </li>
                </ul>
            </Container>
        </nav>
    )
}

export default Navbar