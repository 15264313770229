import styles from './bulma.min.css'
import cmp from '../../img/img_home/cmp.png'
import anaj from '../../img/img_home/anaj.jpg'
import gc_img2 from '../../img/img_home/gc_line.png'
import conf1 from '../../img/img_home/conf1.jpeg'
import gc from '../../img/img_home/gc.png'
import '../../css/googleFonts.css'
import conf2 from '../../img/img_home/conf2.jpeg'


function Home(){
    

    return(
        <>

        <section className={styles.containerCarousel}>
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-interval="false" >
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={conf1} alt=""/>
                    {/*
                    <div class="carousel-caption d-none d-md-block">
                    <h5>Compre com Segurança</h5>
                    <p>A sua segurança é a prioridade do nosso negócio.</p>
                    
                    <div class="slider-btn">
                        <button class="btn btn-1">
                            <a href="">
                                Nossos Carros
                            </a>
                        </button>

                        <button class="btn btn-2">
                            <a href="">
                                Mais Informações
                            </a>
                        </button>
                    </div>
                </div>
                */}
            </div>
            <div class="carousel-item">
                <img src={cmp} alt=""/>
                {/* 
                <div class="carousel-caption d-none d-md-block">
                <h5>Com o melhor Preço</h5>
                <p>Estamos atento ao mercado, o melhor preço é nosso alvo</p>
                
                <div class="slider-btn">
                    <button class="btn btn-1">
                        <a href="">
                            Nossos Carros
                        </a>
                    </button>

                    <button class="btn btn-2">
                        <a href="">
                            Mais Informações
                        </a>
                    </button>
                </div>
                </div>
                */}
            </div>
            
            
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
            </button>
        </div>

        

        </section>

            {/*
            <section class='home_video'>
                <div class='h_video'>
                    <video src={videoBg} autoPlay loop muted />
                </div>
                <button class='btn_sales' onClick={Home}>
                    <a href='https://gateway.ifthenpay.com/forms/?k=QkdQTlUtNjYzNjE2MjAyMDI0MDcwNjIwMjQwNjAxMDc1OTA2&lang=pt&t=BGPNU-663616202024070620240601075906&pbl=AVwX0GcjoN' alt='button_sales' target='_blankt'>
                        Comprar Ingressos
                    </a>
                </button>
            </section>
            */}

            {/*
            <section className={styles.home_xperience}>
                <div class={styles.home_xperience_pg}>
                <h1>7 ANOS<br/> LAGOINHA BRAGA</h1>
                    <div class={styles.home_xperience_notice}>
                        <div class={styles.home_xperience_description}>
                            <h3>
                            Prepare-se! Um ajuntamento de milhares de pessoas e convidados incríveis com um só objetivo: Adorar a Deus! <br/>
                            Será um tempo sobrenatural e você não pode ficar de fora dessa atmosfera de céu aberto que acontecerá no aniversário da nossa igreja Lagoinha Braga, dia 06 de Julho no complexo do Estádio do Braga. 
                            </h3>
                            
                            <button class={styles.btn_xperience} onClick={Home}>
                                <a href='https://gateway.ifthenpay.com/forms/?k=QkdQTlUtNjYzNjE2MjAyMDI0MDcwNjIwMjQwNjAxMDc1OTA2&lang=pt&t=BGPNU-663616202024070620240601075906&pbl=AVwX0GcjoN' alt='button_xperience' target='_blankt'>
                                PAGAMENTOS<br/>NACIONAIS
                                </a>
                            </button>
                            <button class={styles.btn_inter} onClick={Home}>
                                <a href='https://www.paypal.com/donate/?hosted_button_id=VZEZPXTVKJSSJ' alt='button_xperience' target='_blankt'>
                                PAGAMENTOS<br/>INTERNACIONAIS
                                </a>
                            </button>
                            
                            
                        </div>
                        <div class={styles.home_xperience_image} >
                            <img src={SeteAnos} alt='imagem de noticia' title="imagem de noticia"/>
                        </div>
                    </div>
                </div>
            </section>
            */}
            
            
            <section class="section is-link py-6">
                <div class="columns is-vcentered">
                    <div class="column is-8">
                    <h1 class="title">CONFRATERNIZAÇÃO REGIONAL <br/> NORTE PORTUGAL</h1>
                    <h2 class="subtitle">
                        Prepare-se! Nossa confraternização Regional terá como convidados Pr.Valvassoura e a Cantora Gabi Sampaio.<br/>
                    </h2>
                    <p>
                        Para mais informações, entre em contacto connosco através dos nossos meios de comunicação.
                    </p>
                    {/* 
                    <button class="button is-normal is-responsive">
                        <a href='https://gateway.ifthenpay.com/forms/?k=QkdQTlUtNjYzNjE2MjAyMDI0MDcwNjIwMjQwNjAxMDc1OTA2&lang=pt&t=BGPNU-663616202024070620240601075906&pbl=AVwX0GcjoN' alt='link-youtube' target='_blank'>
                            COMPRA <br/> NACIONAL
                        </a>
                    </button>
                    <button class="button is-normal is-responsive">
                        <a href='https://www.paypal.com/donate/?hosted_button_id=VZEZPXTVKJSSJ' alt='button_xperience' target='_blankt'>
                        COMPRA<br/>INTERNACIONAL
                        </a>
                    </button>
                    */}
                    
                    </div>
                    <div class="column">
                    <figure class="image is-3by4">
                        <img src={conf2} />
                    </figure>
                    
                    </div>
                </div>
            </section>
            

            <section class="section is-link py-2">
                <div class="columns is-vcentered">
                    <div class="column is-8">
                    <h1 class="title">NOSSOS CULTOS</h1>
                    <h2 class="subtitle">
                        Acompanhe os nossos cultos e prepare-se para ser transformado pela mensagem da cruz. Acreditamos que servir a Deus e amar pessoas é a melhor forma de cumprir o propósito pelo qual fomos criados. 
                    </h2>
                    <button class="button is-normal is-responsive" onClick={Home}>
                        <a href='https://www.youtube.com/@LagoinhaBraga' alt='link-youtube' target='_blank'>
                            Youtube
                        </a>
                    </button>
                    <button class="button is-normal is-responsive" onClick={Home}>
                        <a href='Cultos' alt='link-cultos' target=''>
                            Cultos
                        </a>
                    </button>
                    
                    </div>
                    <div class="column">
                    <figure class="image is-4by5">
                        <img src={anaj}/>
                    </figure>
                    </div>
                </div>
            </section>

            {/*
            <section className={styles.home_clt}>
                <div class={styles.home_clt_pg}>
                <h1> NOSSOS CULTOS</h1>
                    <div class={styles.home_clt_notice}>
                        <div class={styles.home_clt_description}>
                            <h3>
                            Acompanhe nossos cultos, e prepare-se para ser transformado <br/> 
                            pela mensagem da Cruz. Acreditamos que servir a Deus <br/>
                            e amar pessoas é a melhor forma de cumprir o propósito <br/> 
                            pelo qual fomos criados.
                            
                            </h3>
                            
                            <button class={styles.btn_youtube} onClick={Home}>
                                <a href='https://www.youtube.com/@LagoinhaBraga' alt='button_youtube' target='_blankt'>
                                Youtube
                                </a>
                            </button>
                            <button class={styles.btn_clt} onClick={Home}>
                                <a href='#Cultos' target='' >
                                Cultos
                                </a>
                            </button>
                            
                        </div>
                        <div class={styles.home_clt_image} >
                            <img src={logo} alt='imagem de noticia' title="imagem de noticia"/>
                        </div>
                    </div>
                </div>
            </section>
            */}
            <section class="section is-link py-1">
                <div class="columns is-vcentered">
                    <div class="column is-8">
                        <figure class="image is-4by5">
                            <img src={gc_img2}/>
                        </figure>
                    </div>

                    <div class="column">
                        <h1 class="title">GC</h1>
                        <h2 class="subtitle">
                        A Lagoinha é uma igreja local focada na importância de cuidar com muito zelo de cada pessoa que se alimenta da Palavra de Deus em nossos cultos.
                        </h2>
                        <button class="button is-normal is-responsive" onClick={Home}>
                        <a href='Gcs' alt='link-gc' target=''>
                            HORÁRIOS E LOCALIZAÇÕES
                        </a>
                        </button>
                    </div>
                </div>
            </section>

            
        </>
    )
}

export default Home