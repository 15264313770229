function CreateUUID() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      ((c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & 15)) >> c / 4).toString(16)
    )
  }

export const smallGroups = [
    {
        id: CreateUUID(),
        title: 'GC de Homens - Sexta',
        dayOfWeek: 'Quinta-feira',
        startAt: '20:00',
        address: {
            street: 'Rua Carlos Teixeira',
            number: 19,
            complement: '1° Direito',
            neighborhood: 'São Lazaro',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/ijstvDj7555x8jpt8',
        contact: '963625092',
        comments: 'Todas as 1as e 3as sextas do mês.'
    },
    {
        id: CreateUUID(),
        title: 'GC de Homens - Terça',
        dayOfWeek: 'Terça-feira',
        startAt: '20:00',
        address: {
            street: 'Rua Padre Antonio Vieira',
            number: 122,
            complement: '6° Esquerdo Frente',
            neighborhood: 'São Victor',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/QSe2qRFEbtFWwAnq5',
        contact: '932356292',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - São Vicente 1',
        dayOfWeek: 'Terça-feira',
        startAt: '20:00',
        address: {
            street: 'Praça das Fontainhas',
            number: 11,
            complement: '6° Direito',
            neighborhood: 'São Vicente',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/P2ziGk7JiswFJFar7',
        contact: '937819553',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - São Lázaro',
        dayOfWeek: 'Quinta-feira',
        startAt: '19:30',
        address: {
            street: 'Rua Almeida Garret',
            number: 51,
            complement: '1° Direito',
            neighborhood: 'São Lazaro',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/2veC1xX3a4V1o9ND8',
        contact: '924243033',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - Ruilhe',
        dayOfWeek: 'Sábado',
        startAt: '19:30',
        address: {
            street: 'Rua de Mourens',
            number: 42,
            complement: '1° Direito',
            neighborhood: 'Ruilhe',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/ABQQdZbPzXHDhUxA7',
        contact: '966037562',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - Parretas',
        dayOfWeek: 'Quinta-feira',
        startAt: '19:30',
        address: {
            street: 'Rua Dr. Cónego Manuel Faria',
            number: 77,
            complement: '',
            neighborhood: 'Maximinus',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/hBK8RTdf6DigpeGE6',
        contact: '912728710',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - Fujacal',
        dayOfWeek: 'Terça-feira',
        startAt: '20:30',
        address: {
            street: 'Rua Monsenhor Airosa',
            number: 56,
            complement: '3° andar',
            neighborhood: 'Fujacal',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/Enm9hYjGPUS4ak598',
        contact: '915352958',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - Centro 2',
        dayOfWeek: 'Sábado',
        startAt: '17:00',
        address: {
            street: 'Avenida Liberdade',
            number: 291,
            complement: 'R/C Direito',
            neighborhood: 'Centro',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/UmMr9rUYiPefwL6VA',
        contact: '927409147',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Família - Centro 1',
        dayOfWeek: 'Quarta-feira',
        startAt: '19:30',
        address: {
            street: 'Rua de Diu',
            number: 44,
            complement: '',
            neighborhood: 'Cave',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/NRPf7C9TkwaG4rh4A',
        contact: '933307160',
        comments: ''
    },
    {
        id: CreateUUID(),
        title: 'GC de Casais',
        dayOfWeek: 'Segunda-feira',
        startAt: '20:00',
        address: {
            street: 'Avenida Antonio Palha',
            number: 61,
            complement: '2° Direito Frente',
            neighborhood: 'Lamaçães',
            city: 'Braga'
        },
        mapUrl: 'https://maps.app.goo.gl/ZQzeRD6WJveU2S848',
        contact: '910676103',
        comments: ''
    },
];