import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from './components/pages/Home'
import Quemsomos from './components/pages/Quemsomos'
import Cultos from './components/pages/Cultos'

import Gcs from "./components/pages/Gcs"
import DizimosOfertas from "./components/pages/DizimosOfertas"

import Cursos from "./components/pages/Cursos"
import Carisma from "./components/pages/Carisma"
import Privacy from "./components/pages/Privacy"

import Container from "./components/Layout/Container"
import Navbar from "./components/Layout/Navbar"
import Footer from "./components/Layout/Footer"

import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
/*Slider library
import { register } from "swiper/element/bundle"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
register();
*/ 

function App() {
  return (
    <Router>
      <Navbar/>
      <Container customClass="min-height">
      <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/quemsomos" element={<Quemsomos/>}/>
          <Route path="/cultos" element={<Cultos/>}/>
          <Route path="/gcs" element={<Gcs/>}/>
          <Route path="/dizimosofertas" element={<DizimosOfertas/>}/>
          <Route path="/cursos" element={<Cursos/>}/>
          <Route path="/cursos/carisma" element={<Carisma/>}/>
          <Route path="/carisma" element={<Carisma/>}/>
          <Route path="/privacy" element={<Privacy/>}/> 
      </Routes>
      </Container>
      <Footer/>
    </Router>
  )
}

export default App;
