import styles from './Container.module.css'

function Container(props){
    return(
        <div className={`${styles.container} ${styles[props.customClass]}`}>
            {props.children /* chamando todos os elementos dentro de container.app.js*/}
            
        </div>
    )
}

export default Container