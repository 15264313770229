import carismaBanner from '../../img/courses/carisma-course.jpg'

function Cursos(){
    return (
        <>
        <main class="container is-fluid has-background-black">
        <section class="hero is-black is-small">
            <div class="hero-body">
                <p class="title has-text-centered is-uppercase">
                Cursos
                </p>
            </div>
        </section>
        <section class="section">
            <div class="columns">
                <div class="column">
                    
                    <figure class="image">
                        <img src={carismaBanner} class="is-rounded" alt="Carisma Curso Banner"/>
                    </figure>
                </div>
                <div class="column has-text-white">
                    <header class="title is-3 has-text-white">
                        Seminário Carisma
                    </header>
                    <p>
                        Mais do que um seminário bíblico, o Carisma é uma escola que através da Palavra revelada e do Espírito Santo traz um ensino prático e vivo, empoderando você a viver o propósito pelo qual existe!
                    </p>
                    <p>
                        <strong class="has-text-white">Duração:</strong> <br />
                        1 ano
                    </p>
                    <p>
                        <strong class="has-text-white">Turmas:</strong>
                    </p>
                    <p>
                        ​Sábados | <time datetiem="9:00">9h</time> às <time datetime="18:00">18:00h</time>
                    </p>
                    <a href="https://linktr.ee/carisma.braga?utm_source=linktree_profile_share&ltsid=dc42037f-4fa4-43bd-b72d-0f738a1103e5" class="button is-warning is-medium" title="Clique para saber mais informações">
                        Saiba mais
                    </a>
                </div>
            </div>
        </section>
        </main>
        </>
    )
}

export default Cursos