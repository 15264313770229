import React from 'react'
import styles from './Footer.module.css'
import face from '../../img/icons_footer/face.png'
import insta from '../../img/icons_footer/insta.png'
import youtu from '../../img/icons_footer/youtu.png'
import whats from '../../img/icons_footer/whats.png'


function Footer(){
    return(
        <div className={styles.footer}>
            <div className={styles.sb_footer}>
                <div className={styles.sb_footer_links}>
                    
                    <div className={styles.sb_footer_links_div}>
                        <div className={styles.socialmedia}>
                            <p>
                                <a href="https://www.facebook.com/IgrejaBatistaLagoinhaBraga/" title="Ir para o Facebook" target='_blankt'>
                                    <img src={face} alt="Facebok Icon"/>
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/lagoinhabraga" title="Ir para o Instagram" target="_blankt">
                                    <img src={insta} alt='Instagram Icon'/>
                                </a>
                            </p>
                            <p>
                                <a href="https://www.youtube.com/@LagoinhaBraga" title="Ir para o YouTube" target="_blankt">
                                    <img src={youtu} alt='YouTube Icon'/>
                                </a>
                            </p>
                            <p>
                                <a href="https://api.whatsapp.com/send?phone=+351916787651&text=Olá,%20!%20Gostaria%20de%20de%20uma%20informação%20:" title="Ir para o Whats App" target="_blankt">
                                    <img src={whats} alt='Whats App Icon'/>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <hr></hr>

                <div className={styles.sb_footer_below}>
                    <div className={styles.sb_footer_copyright}>
                        <p>
                            @{new Date().getFullYear()} CodeInn. All right reserved
                        </p>
                    </div>
                    <div className={styles.sb_footer_below_links}>
                        <a href='/terms'><div><p>Terms $ Conditions</p></div></a>
                        <a href='/privacy'><div><p>Privacy</p></div></a>
                        <a href='/security'><div><p>Security</p></div></a>
                        <a href='/cookie'><div><p>Cookies</p></div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer


