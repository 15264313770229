function Carisma(){
    return (
        <>
        <main class="container is-fluid has-background-black">
        <section class="hero is-black is-small">
            <div class="hero-body">
                <p class="title has-text-centered is-uppercase">
                Carisma
                </p>
                <p class="subtitle has-text-centered is-uppercase">
                    Seminário Teológico
                </p>
            </div>
        </section>
        <section class="section has-text-centered">
            <h4 class="has-text-danger is-uppercase">
                Nossa Missão
            </h4>
            <p class="has-text-white">
                Anunciar, advertir e ensinar a todo homem em toda a sabedoria, a fim de apresentar todo homem perfeito em Cristo (Col 1:28) para o desempenho do seu serviço e para a edificação do corpo de Cristo (Ef 4:12).
            </p>
            <h4 class="has-text-danger is-uppercase">
                Nossa Visão
            </h4>
            <p class="has-text-white">
                Treinar ministros na Palavra e no Espírito, impactando, assim, a nossa pátria e o mundo com o Evangelho de Jesus Cristo. 
            </p>
            <h2 class="has-text-white">
                Porque Escolher o Carisma?
            </h2>
            <h4 class="has-text-danger is-uppercase">
                Tradição  e Confiança
            </h4>
            <p class="has-text-white">
                Treinar ministros na Palavra e no Espírito, impactando, assim, a nossa pátria e o mundo com o Evangelho de Jesus Cristo.
            </p>
            <h4 class="has-text-danger is-uppercase">
                Corpo Docente Qualificado
            </h4>
            <p class="has-text-white">
                Além de compromissados com a Palavra de Deus, nosso corpo docente é altamente qualificado no que diz respeito à vida ministerial. São anos de experiência sendo ensinados todos os dias.
            </p>
            <h4 class="has-text-danger is-uppercase">
                Preparo e Envio
            </h4>
            <p class="has-text-white">
                Entendemos que você deve cumprir o ide de Jesus! Por isso, além de preparar também te enviamos para cumprir o propósito de Deus. Você está pronto para viver o melho ano da sua vida?
            </p>
        </section>
        <section class="section has-text-centered has-background-danger has-text-white">
            <header>
                <h2 class="is-2 has-text-white">
                    Disciplinas
                </h2>
            </header>
            <p>
             As disciplinas do Curriculum do Carisma <br />
             Modular Primeiro Ano são:
            </p>
            <p>
                Fundamentos da Fé <br />
                Crescimento Espiritual <br />
                Realidades da Nova Criação <br />
                Fruto do Espírito <br />
                Direção Espiritual <br />
                Casamento e Família <br />
                Submissão e Autoridade <br />
                Liderança Cristã <br />
                Dons Espirituais <br />
                Dons Ministeriais <br />
                Prosperidade Bíblica <br />
                Cristo, Aquele que Cura <br />
                A Pessoa do Espírito Santo <br />
                Hermenêutica <br />
                Autoridade do Crente <br />
                Justiça de Deus <br />
                Aliança de Sangue <br />
                Relacionamentos Interpessoais <br />
                Oração <br />
            </p>
            <p>
                <strong class="has-text-white">Duração do curso:</strong> 1 ano
            </p>
        </section>
        <section class="section has-text-centered has-background-white">
            <header>
                <h2 class="is-2 is-uppercase">
                    Investimento
                </h2>
            </header>
            <p>
                O valor mensal do curso é de R$ 350,00 incluindo o material didático.
            </p>
            <p>
                Seu familiar (cônjuge ou dependente) ganha uma bolsa de 50% para estudar com você!
            </p>
            <p>
                Valor para dupla: R$ 525,00
            </p>
            <p>
                Até dia 31/07 a Matrícula tem 20% desconto, não perca a oportunidade.
            </p>
        </section>
        <section class="section has-text-centered has-text-white has-background-black">
            <header>
                <h2 class="is-2 is-uppercase has-text-danger">
                    Horários das Aulas
                </h2>
            </header>
            <h3>
                Turma da Semana
            </h3>
            <p>
                Segundas e Quartas<br />
                <time datetime="19:40">19h40</time> às <time datetime="22:00">22h00</time>
            </p>
            <h3>
                Turma da Sábado
            </h3>
            <p>
                <time datetime="9:00">9h00</time> às <time datetime="13:30">13h30</time>
            </p>
        </section>
        <section class="section has-text-centered has-text-white has-background-danger">
            <header>
                <h1 class="is-1 is-uppercase">
                    Pronto para <br />
                    Viver o melhor <br />
                    ano da sua vida?
                </h1>
            </header>
            <button class="button is-rounded has-text-white is-black" title="Ir para a pré inscrição">
                Fazer pré inscrição
            </button>
        </section>
        </main>
        </>
    )
}

export default Carisma